import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Band from "../components/band"
import SEO from "../components/seo"
import FlowDataTableTopsNav from "../components/top-nav/flow-top-nav"

// Styles
import grid from "../shared/bootstrap-grid.module.css"
import shared from "../shared/shared.module.css"

// Icons
import MovieIcon from "../icons/movie.svg"

export default function FlowDataTableGuideTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`, `${frontmatter.title}`]} />

      <FlowDataTableTopsNav />

      <Band>

        <div className={`${grid.dLgFlex} ${grid.justifyContentBetween}`}>
          <h1>{frontmatter.title}</h1>
          {frontmatter.videoUrl.length > 0 &&
            <a
              href={frontmatter.videoUrl}
              className={`${shared.tigerfaceLink} ${grid.dFlex} ${grid.alignItemsCenter}`}
              target="_blank"
              rel="noopener noreferrer">
              <div className={`${grid.mr2}`} style={{
                width: '30px',
                height: '30px',
                fill: 'currentColor'
              }}>
                <MovieIcon />
              </div>
              View Video
          </a>}
        </div>

        <div
          className={`${grid.mtLg0} ${grid.mt4}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Band>
    </Layout>
  )
}

export const pageQuery = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      path
      title
      videoUrl
    }
  }
}`
